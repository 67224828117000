import type { OcrTransactions } from "@silexpert/core";

export const useAdjustBankStatementStore = defineStore("adjustBankStatement", {
  state: () => {
    return {
      bankStatementsWaitlist: [] as OcrTransactions[],
      shouldDisplayThatImportIsPending: false as boolean,
      statusOcrExtraction: "inactive" as "pending" | "finished" | "inactive" | "errored",
      bankAccountSelectedForExtraction: null as number | null,
    };
  },
  getters: {},
  actions: {
    reset() {
      this.bankStatementsWaitlist = [];
      this.shouldDisplayThatImportIsPending = false;
      this.statusOcrExtraction = "inactive";
      this.bankAccountSelectedForExtraction = null;
    },
  },
});
